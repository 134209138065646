/*--------------
3.1.4 Custom Forms
----------------*/
.icustom-forms{
   .form-control{
      border-radius: 0;
      background-color: transparent;
      border: 0;
      border-bottom-style: none;
      border-bottom-width: 0px;
      border-bottom: 1px solid #d6d7d9;
      padding: 0rem 0 0.75rem;
      height: auto;
      &:focus{
         border-color: $primary;
      }
   }
}
//Contact Us sec from about us page
.contact-us-sec{
   .featured-contact-map{
      min-height: 600px;
		position: relative;
		margin-right: 30px;
		margin-bottom: 30px;
		&::after{
			position: absolute;
			content: "";
			width: 100%;
			height: 100%;
			border: 10px solid rgba($primary,0.8);
			top: 30px;
			left: 30px;
			z-index: -1;			
		}
   }
}
@media(max-width:567px){
   .contact-us-sec{
      .featured-contact-map{
         margin-right: 15px;
         margin-bottom: 15px;
         &::after{
            top: 15px;
            left: 15px;	
         }
      }
   }
}