:root {
  --primary-color: #AB61C6;
  --primary-color-darken: #9453ac;
  --secondary-color: #4F4F4F;
  --secondary-color-darken: #353535;
  --info-color: #6CCCBC;
  --info-color-darken: #5cafa1;

  --success-color: #6CCCBC;
  --success-color-darken: #5cafa1;

  --dark-color: #031414;
  --dark-color-darken: #000000;
  --light-color: #f2f2f2;
  --light-color-darken: #c5c5c5;
  --muted-color: #888888;
  --muted-color-darken: #686868;

  --info-font-color: --light-color;

  --extra-color: #c68961;
  --text-font: lato;
  --title-font: lato;
}

*:focus {
  outline: none !important;
}

@keyframes fadeInEntrance {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  animation: fadeInEntrance 1s;
  transition: opacity 1s;
  opacity: 1;
}

svg {
  fill: currentColor;
}

// BOOTSTRAP

.invisible {
  visibility: initial !important;
  opacity: 0 !important;
  transition: opacity 1s;
}
.subtitle-block{
  p {
    font-size: 1.5rem!important;
    a {
      color: var(--info-color) !important;
      font-size: 1.5rem!important;
    }
  }
}
.banner-text p {
  font-size: 1.1rem !important;
  color: var(--secondary-color) !important;
  a {
    color: var(--info-color) !important;
    
  }
}
.text-primary {
  color: var(--primary-color) !important;
}

.text-secondary {
  color: var(--secondary-color) !important;
}

.text-info {
  color: var(--info-color) !important;
}

.text-muted {
  color: var(--muted-color) !important;
}

.text-dark {
  color: var(--dark-color) !important;
}

.text-extra {
  color: var(--extra-color) !important;
}

a {
  &.text-primary {
    &:hover, &:focus {
      color: var(--primary-color-darken) !important;
    }
  }

  &.text-secondary {
    &:hover, &:focus {
      color: var(--secondary-color-darken) !important;
    }
  }

  &.text-info {
    &:hover, &:focus {
      color: var(--info-color-darken) !important;
    }
  }

  &.text-muted {
    &:hover, &:focus {
      color: var(--muted-color-darken) !important;
    }
  }

  &.text-dark {
    &:hover, &:focus {
      color: var(--dark-color-darken) !important;
    }
  }

  &.text-extra {
    &:hover, &:focus {
      color: var(--extra-color) !important;
    }
  }
}

span[class^="text-"] {
  font-size: inherit;
}

.bg-primary {
  background-color: var(--primary-color) !important;
}

.bg-info {
  background-color: var(--info-color) !important;
}

.bg-extra {
  background-color: var(--extra-color) !important;
}




.btn {
  font-size: 1.1rem;
  min-height: 30px;
  max-height: 36px;
  border: none !important;
  
  
  letter-spacing: .86px;

  text-transform: none;
  font-weight: 700;
  border-radius: 5rem !important;
 
  display: inline-flex;
  justify-content: center;
  align-content: center;
  align-items: center;

  transition: all .3s ease;
 

  &.btn-primary {
    background-color: var(--primary-color) !important;

    &:active, &:hover {
      background-color: var(--primary-color-darken);
    }
  }

  &.btn-secondary {
    background-color: var(--secondary-color) !important;

    &:active, &:hover {
      background-color: var(--secondary-color-darken);
    }
  }

  &.btn-success {
    background-color: var(--success-color) !important;
    border: none !important;

    &:active, &:hover {
      background-color: var(--success-color-darken) !important;
    }
  }

  &.btn-info {
    background-color: var(--extra-color);
    color: var(--info-font-color) !important;

    &:active, &:hover {
      background-color: var(--extra-color) !important;
    }

    &.disabled, &:disabled {
      &:hover {
        background-color: var(--info-color);
        cursor: initial;
      }
    }
  }

  &.btn-light {
    background-color: #e2e2e2;
  }

  &.btn-link {
    color: var(--primary-color);
    font-weight: normal;
    padding: 0.5rem;
  }
  &.btn-search {
    color: var(--info-color) !important;

  }

  &.btn-big {
    height: 60px;
    border-radius: 0px;
  }
}

.nav-link {
  text-transform: none;
  padding-left: 5px !important; 
  &:hover {
    color: var(--info-color) !important;
  }

  &.active-link {
    color: var(--info-color) !important;

    &:hover {
      color: var(--info-color) !important;
    }
  }
}


// SLICK SLIDER

.slick-slide:focus {
  outline: none !important;
}

// MOOTIVA GENERAL
.cover {
  position: relative;
  min-height: calc(100vh - 60px);
  width: 100vw;
  padding-top: 130px;
  padding-bottom: 3rem;

  .cover--imageBackground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      @media screen and (max-width: 767px) {
        filter: brightness(50%)
      }
    }
  }

  .cover--description {
    font-size: 1.8rem !important;
    line-height: 2rem;
  }

  .card {
    box-shadow: 0px 0px 20px #C4C4C4;
  }

  @media screen and (max-width: 767px) {
    min-height: calc(100vh - 123px);
    padding-top: 100px;
    .cover--description, .cover--title {
      color: var(--light-color) !important;
    }
  }
}

.topTitle {
  text-align: center;
  position: relative;

  @media screen and (max-width: 991px) {
    margin-top: 30px !important;
  }
}

h2.pageTitle {
  font-size: 3rem !important;
  font-weight: normal;
}

.btn.btnRound {
  padding: 0;
  width: 40px;
  min-width: 40px;
  height: 40px;
  max-height: 40px;
  border-radius: 50% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.border-bottom {
  border-bottom: 2px solid var(--light-color);
}

.border-top {
  border-top: 2px solid var(--light-color);
}

.pointer:hover {
  cursor: pointer;
}

.modal-open {
  padding: 0 !important;

  .modal {
    padding: 0 !important;
  }
}

.transparent-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, .6);
  filter: blur(2px);
  backdrop-filter: blur(4px);
  border-radius: 12px;
}

.card {
  border-radius: 15px;

  .card-header {
    background-color: inherit;

    &:first-child {
      border-radius: 15px 15px 0 0;
    }
  }

  .card-footer {
    background-color: inherit;

    &:last-child {
      border-radius: 0 0 15px 15px;
    }
  }

}

.lessonContent .desc a {
  color: var(--info-color) !important;
}

.min-height-50vh {
  min-height: 50vh;
}

.cc-revoke {
  display: none !important;
}


.lessonContent .desc p a {

  font-size: 1.2rem;
  white-space: break-spaces;


}


.lessonContent .desc p {
  font-size: 1.2rem;
  white-space: break-spaces;
  color: black;
  font-family: var(--text-font), sans-serif;

}

.titleTextFont {
  font-family: var(--title-font);


}

.textFont {
  font-family: var(--text-font) !important;


}

body {
  font-family: var(--text-font);

}

.btn {
  font-family: var(--text-font) !important;
  max-height: fit-content;
}

.position-relative {
  position: relative;
}
.font-text p{
 
  font-size: 1.2rem !important;
  white-space: break-spaces;
  color: #333333;
  
}

// Quill styles

.ql-align-center{
  text-align: center !important;
}

.ql-align-center img{
  margin:auto
}

.ql-align-right
{
  text-align: right !important;
}

.ql-align-right img{
  float: right;
}

.ql-align-justify
{
  text-align: justify !important;
}

.ql-video {
  width: 100%;
  height: 350px;
}