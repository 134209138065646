/*--------------
3.1.3 Custom Buttons
----------------*/
//button styling

//button style to make any div or <a> look like button
//default button styling
.btn-translucent,
.btn-primary-grad,
.btn-secondary-grad,
.btn-translucent:focus,
.btn-primary-grad:focus,
.btn-secondary-grad:focus,
.btn-translucent:hover,
.btn-secondary-grad:hover,
.btn-primary-grad:hover,
.btn-translucent:active,
.btn-secondary-grad:active{
   border:0;
   box-shadow: none;
   color: $white;
}
.btn,button,a{
   &:focus{
      outline: none;
   }
}
.btn-translucent{   
   border: 2px solid $white;
   background-color:rgba($white, 0);
}
.btn-translucent:hover,.btn-translucent:active{
   background-color:rgba($white, 0.22);
   border: 2px solid $white;
}
.btn-secondary-grad,.btn-secondary-grad:hover,.btn-secondary-grad:active{
   background: $secondary-gradient !important; 
}
.btn-primary-grad,.btn-primary-grad:hover,.btn-primary-grad:focus{
   background: $primary-gradient !important;
}
.btn-click-effect,.btn-click-effect:active{
   box-shadow: 0 4px 0 0 rgba(219,219,219,0.45);
   transform: scale(1) translateY(0);
   transition: all 0.1s ease-in-out;
   transform: scale(1) translateY(0);
}
.btn-click-effect:hover,.btn-click-effect:focus{
   box-shadow: none;
   transform: scale(0.999) translateY(3px);
}
.btn{
   font-size:1rem;
   letter-spacing: 0.86px;
   font-family: 'Lato', sans-serif;
   text-transform: none;
   font-weight: 700;
   border-radius:5rem;
   min-height: 45px;
   display: inline-flex;
   justify-content: center;
   align-content: center;
   align-items: center;
   cursor: pointer;
   transition: all 0.3s ease;

}
.btn:focus,.form-control:focus,.btn:active:focus,.btn.active,.btn:active{
   box-shadow:none !important;
}
.btn-font{
   font-size:1.125rem
}

// btn-square

.btn-square{
   position:relative;
   padding: 1rem 3.75rem 1rem 1.75rem;
   border-radius:30px;
   &:after{
      content: '';
      position: absolute;
      top: calc(50% - 1.5px);
      width: 1.5rem;
      height:3px;
      right: 1.5rem;
      background-color:$white;
   }
	&.btn-white,&.bg-white{
		&::after{
			background-color: $body-color;
		}
	}
}

//
.btn-white{
	background-color: $white !important;
	color:$body-color !important;
}
.btn-outline-comment{
   color: $gray-600;
   border-color: $gray-600;
   &:hover{
      color: $white;
      background-color: $primary;
      border-color: $primary;
      
   }
}

.btn-flex {
   display: inline-flex;
   justify-content: center;
   align-content: center;
   align-items: center;
}

.btn-lighten {
   background: #9C69E2;
   color: #fff !important;
}