:root {
  --primary-color: #AB61C6;
  --primary-color-darken: #9453ac;
  --secondary-color: #4F4F4F;
  --secondary-color-darken: #353535;
  --info-color: #6CCCBC;
  --info-color-darken: #5cafa1;
  --success-color: #6CCCBC;
  --success-color-darken: #5cafa1;
  --dark-color: #031414;
  --dark-color-darken: #000000;
  --light-color: #f2f2f2;
  --light-color-darken: #c5c5c5;
  --muted-color: #888888;
  --muted-color-darken: #686868;
  --info-font-color: --light-color;
  --extra-color: #c68961;
  --text-font: lato;
  --title-font: lato;
}

*:focus {
  outline: none !important;
}

@keyframes fadeInEntrance {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeIn {
  animation: fadeInEntrance 1s;
  transition: opacity 1s;
  opacity: 1;
}

svg {
  fill: currentColor;
}

.invisible {
  visibility: initial !important;
  opacity: 0 !important;
  transition: opacity 1s;
}

.subtitle-block p {
  font-size: 1.5rem !important;
}
.subtitle-block p a {
  color: var(--info-color) !important;
  font-size: 1.5rem !important;
}

.banner-text p {
  font-size: 1.1rem !important;
  color: var(--secondary-color) !important;
}
.banner-text p a {
  color: var(--info-color) !important;
}

.text-primary {
  color: var(--primary-color) !important;
}

.text-secondary {
  color: var(--secondary-color) !important;
}

.text-info {
  color: var(--info-color) !important;
}

.text-muted {
  color: var(--muted-color) !important;
}

.text-dark {
  color: var(--dark-color) !important;
}

.text-extra {
  color: var(--extra-color) !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: var(--primary-color-darken) !important;
}
a.text-secondary:hover, a.text-secondary:focus {
  color: var(--secondary-color-darken) !important;
}
a.text-info:hover, a.text-info:focus {
  color: var(--info-color-darken) !important;
}
a.text-muted:hover, a.text-muted:focus {
  color: var(--muted-color-darken) !important;
}
a.text-dark:hover, a.text-dark:focus {
  color: var(--dark-color-darken) !important;
}
a.text-extra:hover, a.text-extra:focus {
  color: var(--extra-color) !important;
}

span[class^=text-] {
  font-size: inherit;
}

.bg-primary {
  background-color: var(--primary-color) !important;
}

.bg-info {
  background-color: var(--info-color) !important;
}

.bg-extra {
  background-color: var(--extra-color) !important;
}

.btn {
  font-size: 1.1rem;
  min-height: 30px;
  max-height: 36px;
  border: none !important;
  letter-spacing: 0.86px;
  text-transform: none;
  font-weight: 700;
  border-radius: 5rem !important;
  display: inline-flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  transition: all 0.3s ease;
}
.btn.btn-primary {
  background-color: var(--primary-color) !important;
}
.btn.btn-primary:active, .btn.btn-primary:hover {
  background-color: var(--primary-color-darken);
}
.btn.btn-secondary {
  background-color: var(--secondary-color) !important;
}
.btn.btn-secondary:active, .btn.btn-secondary:hover {
  background-color: var(--secondary-color-darken);
}
.btn.btn-success {
  background-color: var(--success-color) !important;
  border: none !important;
}
.btn.btn-success:active, .btn.btn-success:hover {
  background-color: var(--success-color-darken) !important;
}
.btn.btn-info {
  background-color: var(--extra-color);
  color: var(--info-font-color) !important;
}
.btn.btn-info:active, .btn.btn-info:hover {
  background-color: var(--extra-color) !important;
}
.btn.btn-info.disabled:hover, .btn.btn-info:disabled:hover {
  background-color: var(--info-color);
  cursor: initial;
}
.btn.btn-light {
  background-color: #e2e2e2;
}
.btn.btn-link {
  color: var(--primary-color);
  font-weight: normal;
  padding: 0.5rem;
}
.btn.btn-search {
  color: var(--info-color) !important;
}
.btn.btn-big {
  height: 60px;
  border-radius: 0px;
}

.nav-link {
  text-transform: none;
  padding-left: 5px !important;
}
.nav-link:hover {
  color: var(--info-color) !important;
}
.nav-link.active-link {
  color: var(--info-color) !important;
}
.nav-link.active-link:hover {
  color: var(--info-color) !important;
}

.slick-slide:focus {
  outline: none !important;
}

.cover {
  position: relative;
  min-height: calc(100vh - 60px);
  width: 100vw;
  padding-top: 130px;
  padding-bottom: 3rem;
}
.cover .cover--imageBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.cover .cover--imageBackground img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media screen and (max-width: 767px) {
  .cover .cover--imageBackground img {
    filter: brightness(50%);
  }
}
.cover .cover--description {
  font-size: 1.8rem !important;
  line-height: 2rem;
}
.cover .card {
  box-shadow: 0px 0px 20px #C4C4C4;
}
@media screen and (max-width: 767px) {
  .cover {
    min-height: calc(100vh - 123px);
    padding-top: 100px;
  }
  .cover .cover--description, .cover .cover--title {
    color: var(--light-color) !important;
  }
}

.topTitle {
  text-align: center;
  position: relative;
}
@media screen and (max-width: 991px) {
  .topTitle {
    margin-top: 30px !important;
  }
}

h2.pageTitle {
  font-size: 3rem !important;
  font-weight: normal;
}

.btn.btnRound {
  padding: 0;
  width: 40px;
  min-width: 40px;
  height: 40px;
  max-height: 40px;
  border-radius: 50% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.border-bottom {
  border-bottom: 2px solid var(--light-color);
}

.border-top {
  border-top: 2px solid var(--light-color);
}

.pointer:hover {
  cursor: pointer;
}

.modal-open {
  padding: 0 !important;
}
.modal-open .modal {
  padding: 0 !important;
}

.transparent-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.6);
  filter: blur(2px);
  backdrop-filter: blur(4px);
  border-radius: 12px;
}

.card {
  border-radius: 15px;
}
.card .card-header {
  background-color: inherit;
}
.card .card-header:first-child {
  border-radius: 15px 15px 0 0;
}
.card .card-footer {
  background-color: inherit;
}
.card .card-footer:last-child {
  border-radius: 0 0 15px 15px;
}

.lessonContent .desc a {
  color: var(--info-color) !important;
}

.min-height-50vh {
  min-height: 50vh;
}

.cc-revoke {
  display: none !important;
}

.lessonContent .desc p a {
  font-size: 1.2rem;
  white-space: break-spaces;
}

.lessonContent .desc p {
  font-size: 1.2rem;
  white-space: break-spaces;
  color: black;
  font-family: var(--text-font), sans-serif;
}

.titleTextFont {
  font-family: var(--title-font);
}

.textFont {
  font-family: var(--text-font) !important;
}

body {
  font-family: var(--text-font);
}

.btn {
  font-family: var(--text-font) !important;
  max-height: fit-content;
}

.position-relative {
  position: relative;
}

.font-text p {
  font-size: 1.2rem !important;
  white-space: break-spaces;
  color: #333333;
}

.ql-align-center {
  text-align: center !important;
}

.ql-align-center img {
  margin: auto;
}

.ql-align-right {
  text-align: right !important;
}

.ql-align-right img {
  float: right;
}

.ql-align-justify {
  text-align: justify !important;
}

.ql-video {
  width: 100%;
  height: 350px;
}