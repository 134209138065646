
img {
  max-width: 100%;
  height: auto;
}

#zmmtg-root {
  display: none;
  top: 20px;
  max-width: 80%;
  z-index: 1;

}

body {
  scroll-behavior: smooth !important;
  overflow-x: hidden !important;
  /* overflow-y: scroll!important; */
  overflow-y: unset !important;

}

.meeting-title {
  font-size: 15px;
}
