/*--------------
3.4.9 FAQ Page
----------------*/
/*==== faq Wrap ====*/
.faq-wrap{
   .faq-card.card{
      border:0px;
      .card-header{
         background:transparent;
         border-bottom:0px;
      }
   }
   .faq-item{
      .faq-ques-link{
         font-weight:600;
         cursor:pointer;
         font-size:1rem;
         line-height: 26px;
         padding-left:1.25rem;
         padding-right:1.25rem;
         position:relative;

         &:after{
            position: absolute;
            content: "\f146";
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            left: 0;
            top:8px;
            font-size:14px;
         }
         &.collapsed::after {
            content: "\f0fe";
         }
      }   
   }   
}