/*--------------
3.3.1 Banner Section
----------------*/
.banner-wrap{
   background-repeat: no-repeat;
   background-size: cover;  
}
.banner-title{
   font-size: 4.5rem;
}
@media (max-width: 600px) {
.banner-overlay, .home-banner-top{
    display: block !important;
    }
.authContainer {
  margin-top: -300px !important;
}
   }
.banner-overlay{
   .sec-title > .banner-title{
      font-weight:600;
      line-height: 1.1;
   }
   // padding: 15rem 0;
 //  background: $banner-overlay-color;
 background-size: cover;

   position: relative;

   min-height: 730px;
   display: flex;


   align-items: center;
   .banner-btn{
      margin-top: 3.5rem;
   }
   a.btn {     
      font-size: 1.05rem;
   //   padding: 1.2rem 2.5rem;  
   }

   &:before {
      content: "";
    background-size: cover;
    height: 110%;
    position: absolute;
    top:0;
    margin-top: 0;
    width: 100%;
    background-image: url(/assets/images/mootiva/sq1.svg);
   }

   
}

.bg_lines { 
&:after {
      content: "";
    background-size: cover;
    height: 100%;
    position: absolute;
    top: 200px;
    z-index: -1;
    width: 100%;
    background-image: url(/assets/images/mootiva/bg_lines.svg);
   }
   }
.banner-wrap-v1{
   background-image: url("https://via.placeholder.com/1920x1280");
}
.pages-common-banner{
   min-height: $banner-height;   
   background-size:cover;
   background-position: center center;
   display: flex;
   align-items: center;
   border-radius: 10px;
   padding: 9.8rem 0 9rem;
   overflow: hidden;
}
@media all and (-ms-high-contrast:none){
   *::-ms-backdrop, .pages-common-banner {
      height: 100px; //for IE
   } /* IE11 */
   *::-ms-backdrop, .select-group select {
      background: transparent;
   }
}
@media(max-width:991px){
   .banner-overlay{
      .sec-title > .banner-title{
         font-size: 4rem;
      }
   }
}
@media(max-width:767px){
   .banner-overlay{
      .sec-title > .banner-title{
         font-size: 3.5rem;
      }
   }
}

.home-banner-top {
  padding: 0;
   display: flex;
   justify-content: space-between;
   align-items: center;
   width: 100%;

   p {
      font-size: 30px;
      line-height: 1;
   }
   img {
      width: 100%!important
   }
}
