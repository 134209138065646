/*--------------
3.2.3 Preloader
----------------*/
/*==========Pre Loader=========*/
html body .loading-bar-fixed > div#loading-bar-spinner {
   position: fixed !important;
   top: 0 !important;
   left: 0 !important;
   left: 0;
   right: 0;
   top: 0 !important;
   bottom: 0;
   background:$white;
   display: flex !important;
   justify-content: center;
   align-items: center;
}

html body div#loading-bar-spinner .spinner-icon {
   width: 60px !important;
   height: 60px !important;
   color: $primary !important;
}

html body .loading-bar-fixed > div .bar {
   background: $primary !important;
}
