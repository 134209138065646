/*--------------
3.2 Core
----------------*/
@import "reset";
@import "header";
@import "preloader";
@import "footer";


.image-overlay .sec-title {
    background-image: url('/src/assets/images/title-background.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 220px;
}


.mt-10 {
    margin-top: 5rem;
}

.mb-10 {
    margin-bottom: 5rem;

}

.nav-link {
    margin-right: 10px;
}

#sticky-nav .white-text-navbar {
    color: black!important
    
}