/*--------------
3.4.1 Pricing Page
----------------*/
/*========= Swicthes css ============*/
.onoffswitch {
	position: relative;
	width: 75px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
}
.onoffswitch-checkbox {
	opacity: 0;
	position: absolute;
	visibility: hidden;
}
.onoffswitch-label {
	display: block;
	overflow: hidden;
	cursor: pointer;
	border-radius: 20px;
}
.onoffswitch-inner {
	display: block;
	width: 200%;
	margin-left: -100%;
	transition: margin 0.3s ease-in 0s;
}
.onoffswitch-inner:before,
.onoffswitch-inner:after {
	display: block;
	float: left;
	width: 50%;
	height: 30px;
	padding: 0;
	line-height: 30px;
	font-size: 14px;
	color: $white;
	font-weight: bold;
	box-sizing: border-box;
}
.onoffswitch-inner:before {
	content: "\f00c";
	font-family: 'FontAwesome';
	padding-left: 10px;
	background-color: $purple;
	color: $white;
}
.onoffswitch-inner:after {
	content: "\f00d";
	font-family: 'FontAwesome';
	padding-right: 10px;
	background-color: $gray-700;
	color: $white;
	text-align: right;
}
.onoffswitch-switch {
	display: block;
	width: 18px;
	margin: 6px;
	background: $white;
	position: absolute;
	top: 0px;
	bottom: 0px;
	right: 43px;
	border-radius: 20px;
	transition: all 0.3s ease-in 0s;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
	margin-left: 0;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
	right: 0px;
}
/*========== Pricing V2 ===========*/
.pricing-banner{
   padding:7rem 1rem;   
	.onoffswitch-inner {
		&::after,
		&::before {
			content: "";
		}
	}
}
.pricing-content-wrap {
	.btn {
		font-size: 15px;
	}
	.buynow-btn {
		padding: 0.7rem 1.5rem;
	}
	.card-feature {
		hr {
         width: 55px;
		}
	}
	.pricing-item {
		border-radius: 10px;
		.card-body {
			padding: 3rem;
			.ribbon {
				display: none;
			}
			.card-feature li {
			 font-size: 1.125rem;         
			 &:last-child hr{
				margin-bottom:0;
			 }
			}
		}
	}
	.popular {
		transform: translateY(-0.2rem);
		box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
		.card-body .ribbon {
			display: block;
			top: 0;
			margin: 0;
			span {
				transform: rotate(-90deg) translate(-7px, 15px);
				height: 70px;
				white-space: nowrap;
				transform-origin: center center;
				text-transform: uppercase;
				font-size: 12px;
			}
		}
	}
}
/*call in action*/
@media(max-width:567px){
   .call-in-action-wrap .resp-title{
      font-size: 2rem;
   }
}
/*======== Ribbon css style ===========*/

.ribbon {
	padding: 5px 15px;
	position: relative;
	margin-bottom: 15px;
	margin-top: 15px;
	z-index: 9;
	span {
		display: block;
		width: 100px;
		max-width: 90%;
		height: 20px;
		line-height: 20px;
		vertical-align: middle;
	}
	&.left {
		margin-left: -30px;
		text-align: left;
		width: 90%;
		&:before {
			content: " ";
			height: 15px;
			width: 15px;
			border-left: 0 solid transparent;
			border-right: 15px solid transparent;
			position: absolute;
			top: 0;
			right: -15px;
		}
		&:after {
			content: " ";
			height: 15px;
			width: 15px;
			border-left: 0 solid transparent;
			border-right: 15px solid transparent;
			position: absolute;
			bottom: 0;
			right: -15px;
		}
	}
	&.right {
		margin-right: -30px;
		float: right;
		text-align: right;
		&:before {
			content: " ";
			height: 15px;
			width: 15px;
			border-left: 0 solid transparent;
			border-left: 15px solid transparent;
			position: absolute;
			top: 0;
			left: -15px;
		}
		&:after {
			content: " ";
			height: 15px;
			width: 15px;
			border-left: 0 solid transparent;
			border-left: 15px solid transparent;
			position: absolute;
			bottom: 0;
			left: -15px;
		}
	}
	&.top-left {
		text-align: center;
		width: 40px;
		margin-top: -25px;
		padding: 0;
		margin-bottom: 30px;
		span {
			width: 100%;
			float: left;
			height: 40px;
			margin: 0;
			line-height: 40px;
			vertical-align: middle;
			display: block;
			max-width: none;
		}
		&:before {
			content: " ";
			height: 20px;
			width: 20px;
			border-bottom: 20px solid transparent;
			border-right: 20px solid transparent;
			position: absolute;
			bottom: -20px;
			left: 0;
		}
		&:after {
			content: " ";
			height: 20px;
			width: 20px;
			border-bottom: 20px solid transparent;
			border-left: 20px solid transparent;
			position: absolute;
			bottom: -20px;
			right: 0;
		}
	}
	&.top-right {
		text-align: center;
		width: 40px;
		margin-top: -25px;
		padding: 0;
		margin-bottom: 30px;
		float: right;
		span {
			width: 100%;
			float: left;
			height: 40px;
			margin: 0;
			line-height: 40px;
			vertical-align: middle;
			display: block;
			max-width: none;
		}
		&:before {
			content: " ";
			height: 20px;
			width: 20px;
			border-bottom: 20px solid transparent;
			border-right: 20px solid transparent;
			position: absolute;
			bottom: -20px;
			left: 0;
		}
		&:after {
			content: " ";
			height: 20px;
			width: 20px;
			border-bottom: 20px solid transparent;
			border-left: 20px solid transparent;
			position: absolute;
			bottom: -20px;
			right: 0;
		}
	}
	&.ribbon-success {
		background: $green;
		color: $white;
		&.right {
			&:before {
				border-top: 15px solid $green;
			}
			&:after {
				border-bottom: 15px solid $green;
			}
		}
		&.top-left {
			background: $green;
			position: absolute;
			left: 10px;
			&:before {
				border-left: 25px solid $green;
			}
			&:after {
				border-right: 25px solid $green;
			}
		}
		&.top-right {
			background: $green;
			position: absolute;
			right: 10px;
			&:before {
				border-left: 25px solid $green;
			}
			&:after {
				border-right: 25px solid $green;
			}
		}
		&.left:before {
			border-top: 15px solid $green;
			border-bottom: 15px solid $green;
		}
	}
	&.ribbon-danger {
		background: $red;
		color: $white;
		&.right {
			&:before {
				border-top: 15px solid $red;
			}
			&:after {
				border-bottom: 15px solid $red;
			}
		}
		&.top-left {
			background: $red;
			position: absolute;
			left: 10px;
			&:before {
				border-left: 25px solid $red;
			}
			&:after {
				border-right: 25px solid $red;
			}
		}
		&.top-right {
			background: $red;
			position: absolute;
			right: 10px;
			&:before {
				border-left: 25px solid $red;
			}
			&:after {
				border-right: 25px solid $red;
			}
		}
		&.left:before {
			border-top: 15px solid $red;
			border-bottom: 15px solid $red;
		}
	}
	&.ribbon-warning {
		background: $orange;
		color: $white;
		&.right {
			&:before {
				border-top: 15px solid $orange;
			}
			&:after {
				border-bottom: 15px solid $orange;
			}
		}
		&.top-left {
			background: $orange;
			position: absolute;
			left: 10px;
			&:before {
				border-left: 25px solid $orange;
			}
			&:after {
				border-right: 25px solid $orange;
			}
		}
		&.top-right {
			background: $orange;
			position: absolute;
			right: 10px;
			&:before {
				border-left: 25px solid $orange;
			}
			&:after {
				border-right: 25px solid $orange;
			}
		}
		&.left:before {
			border-top: 15px solid $orange;
			border-bottom: 15px solid $orange;
		}
	}
	&.ribbon-primary {
		background: $primary;
		color: $white;
		&.right {
			&:before {
				border-top: 15px solid $primary;
			}
			&:after {
				border-bottom: 15px solid $primary;
			}
		}
		&.top-left {
			background: $primary;
			position: absolute;
			left: 10px;
			&:before {
				border-left: 25px solid $primary;
			}
			&:after {
				border-right: 25px solid $primary;
			}
		}
		&.top-right {
			background: $primary;
			position: absolute;
			right: 10px;
			&:before {
				border-left: 25px solid $primary;
			}
			&:after {
				border-right: 25px solid $primary;
			}
		}
		&.left:before {
			border-top: 15px solid $primary;
			border-bottom: 15px solid $primary;
		}
	}
}
@media(max-width:1380px) {
	.pricingV2 .pricing-item .card-body {
		padding: 2rem;
	}
}
@media(max-width:1280px) {
	.pricingV2 .pricing-item .card-body {
		padding: 2rem 1.2rem;
	}
}